<template>
	<div>
		<!--Section: Admin Cards-->
		<section>
			<!--Section heading-->
			<h1 class="font-weight-bold mt-5">{{ current.name }}</h1>
			<small>Settings</small>
			<!--Section description-->
		</section>
		<hr class="my-5" />
		<section>
			<router-view></router-view>
		</section>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	name: 'SettingsView',
	computed: {
		...mapState('accounts', ['current'])
	},
	metaInfo() {
		return {
			title: 'Account Settings'
		}
	}
}
</script>

<style></style>
